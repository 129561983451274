/**
 * External dependencies.
 */
import React, { createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-web';
import { noop } from 'lodash';
import fetch from 'isomorphic-fetch';

/**
 * Internal dependencies.
 */
import Image from '../image';

import './style.scss';

/**
 * Lottie Player Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */



const LottiePlayer = (props) => {
	const {
		mobileJsonUrl,
		desktopJsonUrl,
		className = '',
		onComplete = noop, // Callback on complete.
		onStart = noop, // On start of animation.
		loop = false,
		canStart = true, // Set true to start animation, may be used to pre-load animation and start when required.
		load = true, // JSON data will not be fetched until set to true, may be used to pre-load animation at some time.
		device = 'desktop',
		staticImage = null, // Use static image as a placeholder and loader.
	} = props;

	const stageRef = createRef();
	const [started, setStarted] = useState(false);
	const dataRef = useRef({ mobile: null, desktop: null });

	const loadAnimationData = () => {
		const jsonUrl = 'mobile' === device ? mobileJsonUrl : desktopJsonUrl;

		return new Promise((resolve) => {
			if (!load || !jsonUrl) {
				resolve(null); // This will short-circuit animation.
				return;
			}

			if (dataRef.current[device]) {
				resolve(dataRef.current[device]);
			} else {
				/**
				 * Use `Header set Access-Control-Allow-Origin "*"` in WordPress for
				 * CORS errors. Paste the line in .htaccess in case of Apache server.
				 */

				console.log('jsonUrl', jsonUrl);
				fetch(jsonUrl).then(response => response.json()).then(data => {
					dataRef.current[device] = data;
					resolve(data);
				}).catch(err => console.error(err));
			}
		});
	}

	/**
	 * Setup up lottie player.
	 *
	 * @param {Object} animationData Animation data.
	 */
	const setupPlayer = (animationData) => {
		if (!animationData || !stageRef.current || !canStart) {
			return;
		}

		stageRef.current.innerHTML = '';

		/**
		 * There is a react-lottie package available however it is buggy and the library looks abandoned now.
		 * At the time of development the last commit made was in 2018, so it was not safe to make use of it.
		 * Though it is not technically correct to use airbnb/lottie-web however there was no better alternative
		 * available considering the limited time we had for development.
		 *
		 * @see https://github.com/chenqingspring/react-lottie/issues/112
		 */
		const animation = Lottie.loadAnimation({
			container: stageRef.current,
			renderer: 'svg',
			loop,
			autoplay: true,
			animationData
		});

		const onAnimationComplete = () => {
			onComplete(animation);
			animation.removeEventListener('complete', onAnimationComplete);
		}

		animation.addEventListener('complete', onAnimationComplete);

		onStart(animation);
		setStarted(true);
	}

	useEffect(() => {
		loadAnimationData().then(setupPlayer);
	}, [device, load, canStart]); // eslint-disable-line

	if (!desktopJsonUrl && staticImage) {
		return (
			<Image {...staticImage} />
		);
	}

	const loaderComponent = staticImage ? (<Image {...staticImage} />) : null;

	return (
		<div className={className}>
			{!started && loaderComponent}
			<div ref={stageRef} className="lottie-player" />
		</div>
	);
};

LottiePlayer.propTypes = {
	mobileJsonUrl: PropTypes.string,
	desktopJsonUrl: PropTypes.string,
	className: PropTypes.string,
	onComplete: PropTypes.func,
	onStart: PropTypes.func,
	loop: PropTypes.bool,
	load: PropTypes.bool,
	device: PropTypes.string,
	loader: PropTypes.object,
	staticImage: PropTypes.object,
};

export default LottiePlayer;
